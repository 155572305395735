// Override js-from-routes config to not do serialization,
// and to use our `request` method
import { Config, definePathHelper } from '@js-from-routes/client'
/** @import {Method, PathHelper} from '@js-from-routes/client' */

const noop = (val) => val
Config.deserializeData = noop
Config.serializeData = noop
Config.unwrapResponse = async (res, _) => res
Config.fetch = async ({ method, url, data, ...opts }) => {
  return await request(method, url, data, opts)
}

/**
 * Js-from-routes really doesnt like optional parameters not being set so we
 * need to strip the locale variables out
 *
 * @param {Method} method
 * @param {string} pathTemplate
 * @returns {PathHelper}
 */
function definePathWithOptionalParamsHelper(method, pathTemplate) {
  pathTemplate = pathTemplate.replace(/\(\/:language-:region\)/, '')
  return definePathHelper(method, pathTemplate)
}

// NOTE: we simply reuse the upstream `definePathHelper`, but only after
// overwriting the config. This ensures our config changes are bundled.
export { definePathWithOptionalParamsHelper as definePathHelper }

/** @returns {string} */
export function getAuthenticityToken() {
  const tokenEl = document.getElementsByName('csrf-token')[0]
  if (tokenEl) return tokenEl.getAttribute('content')
}

class ApiError extends Error {
  /**
   * @param {Response} response
   * @param {{ error: { message: any } }} body
   */
  constructor(response, body) {
    super(body.error?.message ?? body)
    this.response = response
  }

  /** @returns {number} */
  get status() {
    return this.response.status
  }
}

/**
 * @param {Method} method
 * @param {RequestInfo | URL} url
 * @param {any} data
 * @returns {Promise<string | Object>}
 */
export async function request(method, url, data, options = {}) {
  const res = await fetch(url, {
    ...options,
    method,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': getAuthenticityToken(),
      ...(options.headers ?? {}),
    },
    body: JSON.stringify(data),
  })

  const body = res.headers?.get('content-type')?.includes('application/json')
    ? await res.json()
    : await res.text()

  if (res.status >= 400) {
    throw new ApiError(res, body)
  }
  return body
}

/**
 * @param {RequestInfo | URL} url
 * @param {any} data
 * @returns {Promise<string | Object>}
 */
export async function put(url, data, options = {}) {
  return await request('PUT', url, data, options)
}

/**
 * @param {RequestInfo | URL} url
 * @param {any} data
 * @returns {Promise<string | Object>}
 */
export async function post(url, data, options = {}) {
  return await request('POST', url, data, options)
}
